import type { NextApiRequest, NextApiResponse } from "next";
import { trackLoginLinkEmailed } from "~/lib/external/segment/server/events";
import { sendLoginLinkEmail } from "~/lib/external/sendinblue/templates";
import { isNumber } from "~/lib/lodash";
import { type RequestAuthTokenClientInput } from "~/pages/api/auth/token/request";
import { createAuthenticationToken } from "~/services/auth/authentication-token/createAuthenticationToken";
import { fetchRequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import { parseInvitationToken } from "~/services/auth/invitationTokenManager";

export const requestLoginLink = async (
  req: NextApiRequest,
  res: NextApiResponse,
  input: RequestAuthTokenClientInput
) => {
  const emailToRequestLinkFor = input.email;
  const invitationToken = parseInvitationToken(input.invitationToken);

  const user = await req.prisma.user.findFirst({
    where: {
      email: emailToRequestLinkFor.toLowerCase(),
      invitationNeedsReview: false,
      ...(isNumber(invitationToken?.companyId) && { companyId: invitationToken.companyId }),
    },
    select: { id: true },
  });

  // To avoid leaking user information, if the email is not found in our database we just fail silently and not send any token
  if (!!user) {
    const authenticatedUser = await fetchRequiredAuthenticatedUser(req, { userId: user.id });
    const token = await createAuthenticationToken(req, { user: authenticatedUser });

    await sendLoginLinkEmail(req, {
      ...input,
      token,
      email: emailToRequestLinkFor,
      locale: authenticatedUser.locale,
    });

    await trackLoginLinkEmailed(req, authenticatedUser);

    await req.session.save();

    res.redirect(`/sign-in?login-link=true&login-link-sent=true&email=${emailToRequestLinkFor}`);
  }
};
