import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { object, string } from "yup";
import { api } from "~/lib/api";
import { createMutation } from "~/lib/reactQuery";
import { type YupOutputType } from "~/lib/utils";
import { requestLoginLink } from "~/services/auth/requestLoginLink";

export const RequestAuthTokenClientSchema = object({
  email: string().email().required(),
  invitationToken: string().optional(),
});

export type RequestAuthTokenClientInput = YupOutputType<typeof RequestAuthTokenClientSchema>;

const handler: NextApiHandler = async (req, res) => {
  const input = RequestAuthTokenClientSchema.validateSync(req.body, {
    abortEarly: false,
  });

  await requestLoginLink(req, res, input);

  res.status(HttpStatus.OK).json({});
};

export default api(handler, {
  method: "POST",
  authentication: { optional: true },
});

export const useRequestTokenMutation = createMutation<typeof handler, typeof RequestAuthTokenClientSchema>({
  path: "/api/auth/token/request",
  schema: RequestAuthTokenClientSchema,
});
